<template>
  <div class="d-flex app-footer justify-content-between">
    <p class="m-0 w-25">
      <span class="float-md-left d-block d-md-inline-block mt-25">
        Copyright  © {{ new Date().getFullYear() }}
      </span>
    </p>
    <div class="d-flex align-items-center">
      <router-link to="/about_us">
        {{ $t('About us').toUpperCase() }}
      </router-link>
      <span class="dot-divider rounded" />
      <router-link to="/terms-and-privacy-policy">
        {{ $t('Privacy Policy').toUpperCase() }}
      </router-link>
    </div>
    <p class="w-25" />
  </div>
</template>

<script>

export default {
  components: {
  },
}
</script>
